import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
// import Navbar from './components/Navbar'
import Test from './views/test'
import "@fontsource/open-sans";
import UserPage from './views/userPage'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
    {/* <Navbar /> */}
    <Routes>
      <Route path="/" element={<App/>}/>
      <Route path="/dashboard" element={<UserPage />}/>
      <Route path="/test" element={<Test/>}/>
    </Routes>
  </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
