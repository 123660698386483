import React, { useState, useEffect } from "react"
import FullCalender from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import axios from "axios"
import Navbar from "../components/Navbar"
import EventPopUP from "../components/EventPopUP"
import WFHLogo from '../assets/wfhicon.svg'
import WFOLogo from '../assets/wfoicon.svg'
import TimeSheet from "../components/timesheet"
import MonthSelector from "../components/MonthSelector"
// import LeaveImage from "../assets/leave.png"
const UserPage = () => {

  const [userEmail, setUserData] = useState(null)
  const [loggedinUser, setloggedinUser] = useState(null)

  const [eventsData, setEventsData] = useState({})

  const [isAdmin, setAdminStatus] = useState(false)

  const [emailList, setEmailList] = useState([])

  const [paramsDate, setDates] = useState({})

  const [showModal, setShowModal] = useState(false)

  const [modalDate, setModalDate] = useState('')
 
  const [AllUserdata, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const INITIAL_COUNT = 1;
  const [count, setCount] = useState(INITIAL_COUNT);
  const [lastPage, setlastPage] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const handleMonthChange = async (newMonth, newYear) => {
    const date = new Date(newYear, newMonth - 1);

    // Get the start date of the month
    let startDate = new Date(date.getUTCFullYear(), date.getUTCMonth()+2, 1);

    // Get the end date of the month by subtracting one day from the start of the next month
    let endDate = new Date(date.getUTCFullYear(), date.getUTCMonth() + 3, 0);

    startDate = convertISTtoGMT(startDate);
    endDate = convertISTtoGMT(endDate);
    await resetDataandCount();
    getAllUserDetailsData(startDate, endDate)
  };  

  const handlePrevClick = () => {
    const newMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const newYear = newMonth === 11 ? currentYear - 1 : currentYear;
    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
    handleMonthChange(newMonth, newYear);
  };

  const handleNextClick = async () => {
    const newMonth = currentMonth === 11 ? 0 : currentMonth + 1;
    const newYear = newMonth === 0 ? currentYear + 1 : currentYear;
    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
    handleMonthChange(newMonth, newYear);
  };

  const getAllUserDetailsData = async (stDate, edDate) => {
    try 
    {
      if (isAdmin) {
        
        if(stDate === undefined && edDate === undefined)
        {
          const now = new Date();
          stDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1));
          edDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 0));
        }

        var getCurrentMonth = currentMonth;
        var getCurrentYear = currentYear;

        var getPassedMonth = stDate.getMonth();
        var getPassedYear = edDate.getFullYear();
        
        let url = `${process.env.REACT_APP_API_URL}admin/client/get-all-employee-activity-details`;
        //let url="https://localhost:3001/admin/client/get-all-employee-activity-details";
        //console.log(url);

        let params = {
          "start_date": stDate,
          "end_date": edDate,
          "pageNumber": count
        };

        let response;

        axios.post(url, params).then((result) => {
          response = result.data;   
          if(response.length<10)
          {
            setlastPage(true);
          }
          else 
          {
            if(getCurrentMonth === getPassedMonth && getCurrentYear === getPassedYear)
            {
              setCount(count + 1);
            }
            else
            {
              setCount(INITIAL_COUNT);
            } 
          }
          //setData(response);
          setData(prevData => [...prevData, ...response]);
        }).catch((err) => {
          console.log(err)
        })
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const getUserDetails = async () => {
    try {
      let url = `${process.env.REACT_APP_API_URL}auth/login/success`
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        }
      });
      const data = await response.json()
      if (data.user.user.email === "manan@avdevs.com" || data.user.user.email === "rahul.b@avdevs.com" || data.user.user.email === "arun.t@avdevs.com") {
        console.log("Setted status true");
        setAdminStatus(true);
      } else {
        setUserData(data.user.user.email);
        console.log("Setted status false");
        setAdminStatus(false);
      }
      setLoading(true);
      setloggedinUser(data.user.displayName)
    }
    catch (err) {
      console.log(err)
    }
  }

  const getUserEmails = async () => {
    let url = `${process.env.REACT_APP_API_URL}admin/client/employee-list`
    if (isAdmin) {
      axios.get(url).then((result) => {
        let details = result.data
        let userList = []
        for (let i = 0; i < details.length; i++) {
          let name = details[i]['first_name'][0] + ' ' + details[i]['last_name'][0]
          let temp = {
            email: details[i]['_id'],
            user_name: name.toUpperCase()
          }
          userList.push(temp);
        }
        setEmailList(userList)
      })
    }
  }

  const fetchUserActivity = async () => {
    if (userEmail && paramsDate.start_date && paramsDate.end_date) {
      let params = {
        email: userEmail,
        start_date: paramsDate.start_date,
        end_date: paramsDate.end_date
      };
      let url = `${process.env.REACT_APP_API_URL}admin/client/employee-activity-details`
      let eventDt = []
      let response;
      axios.post(url, params).then((result) => {
        response = result.data
        for (let i = 0; i < response.length; i++) {
          let temp = {}
          if (response[i].leave === 1) {
            temp = {
              date: response[i].date,
              display: 'background',
              extendedProps: {
                total_time: response[i].total_time,
                start_time: response[i].start_time,
                end_time: response[i].end_time,
                time_range: response[i].time_range,
                missing_time: response[i].missing_time,
                leave: response[i].leave,
                ip:response[i].ip
              }
            }
          } else {
            temp = {
              date: response[i].date,
              extendedProps: {
                total_time: response[i].total_time,
                start_time: response[i].start_time,
                end_time: response[i].end_time,
                time_range: response[i].time_range,
                missing_time: response[i].missing_time,
                leave: response[i].leave,
                ip:response[i].ip
              }
            }
          }

          eventDt.push(temp)
        }
        setEventsData(eventDt)
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  const getEventsData = async (dateInfo) => {
    setDates({ start_date: dateInfo.startStr, end_date: dateInfo.endStr })
  }

  const dropDownMailChange = async (email) => {
    await setUserData(email)
  }

  const handleOpenModal = () => {
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleDateClick = (arg) => {
    setModalDate(arg.dateStr)
    handleOpenModal()
  }

  function resetDataandCount() {
    setCount(INITIAL_COUNT);
    setData([]);
    setlastPage(false);
  }

  function convertISTtoGMT(istDate) {
    // Create a Date object for the input IST date
    const dateIST = new Date(istDate);
  
    // Get the UTC time in milliseconds for the IST date
    const utcTime = Date.UTC(
      dateIST.getUTCFullYear(),
      dateIST.getUTCMonth(),
      dateIST.getUTCDate(),
      dateIST.getUTCHours(),
      dateIST.getUTCMinutes(),
      dateIST.getUTCSeconds()
    );
  
    // Create a new Date object for the UTC time
    const dateGMT = new Date(utcTime);
    return dateGMT;
  }
  function renderEventContent(eventInfo, element, view) {
    if (eventInfo.event.extendedProps.leave === 0) {
      // let officeIps = process.env.OFFICE_IP
      let officeIps = "49.36.88.214,219.91.220.157,49.36.90.196";
      let exist = officeIps.includes(eventInfo.event.extendedProps.ip);
      if(officeIps.includes(eventInfo.event.extendedProps.ip) && eventInfo.event.extendedProps.ip!=null)
      {
        return (
          <>
            <img src={WFOLogo} alt="WFO" align="right"/>
            <br/>
            <h3 className="hour-title text-center">{eventInfo.event.extendedProps.total_time}</h3>
            <p className="min-title text-center">{eventInfo.event.extendedProps.missing_time}</p>
            <p className="time-title text-center">{eventInfo.event.extendedProps.time_range}</p>
          </>
        )
      }
      else if(eventInfo.event.extendedProps.ip!=null)
      {
        return (
          <>
            <img src={WFHLogo} alt="WFH" align="right"/>
            <br/>
            <h3 className="hour-title text-center">{eventInfo.event.extendedProps.total_time}</h3>
            <p className="min-title text-center">{eventInfo.event.extendedProps.missing_time}</p>
            <p className="time-title text-center">{eventInfo.event.extendedProps.time_range}</p>
          </>
        )
      }
      else
      {
        return (
          <>
          </>
        )
      }
    } else {
      return (
        <>
          {/* <img src={LeaveImage} alt="logo" height="120" width="120" />  */}
          <div className="fc-daygrid-bg-harness" style={{ left: "0px", right: "0px" }}>
            {/* <div className="fc-bg-event fc-event fc-event-start fc-event-end fc-event-past"> */}
            <div className="leave-ui">
              <p className="leave-para">L</p>
            </div>
            {/* </div> */}
          </div>
        </>
      )
    }
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  // useEffect(() => {
  //   getUserEmails()
  // }, [isAdmin])

  useEffect(() => {
    fetchUserActivity()
  }, [userEmail, paramsDate])

  useEffect(() => {
    if (loading) {
      getAllUserDetailsData();
      setLoading(false);
    }
  }, [loading,isAdmin]);

  // useEffect(() => {
  //   getAllUserDetailsData();
  // }, []);

  return (
    <>
      {showModal && !isAdmin ? (
        <>
          <EventPopUP closeModal={handleCloseModal} selDate={modalDate} email={userEmail} />
        </>
      ) : (
        <></>
      )}
      <Navbar />
      <section className="section-1">
        <span className="fc-left hello-text">Hello {loggedinUser}!</span>
      {
        isAdmin ? 
        (
          <>
            <MonthSelector
              currentMonth={currentMonth}
              currentYear={currentYear}
              onPrevClick={handlePrevClick}
              onNextClick={handleNextClick}
            />
            <div>
              <TimeSheet data={AllUserdata} lastPage={lastPage} getAllUserDetailsData={getAllUserDetailsData} />
            </div>
          </>
        ) :
        (
          <>
          {/* <div className="sel-employee">
            <select className="form-select shadow" onChange={(event) => dropDownMailChange(event.target.value)}>
              <option value="" selected>Select</option>
              {emailList.map((users) => <option value={users.email}>{users.user_name}</option>)}
            </select>
          </div> */}
          <FullCalender
            height={'1000px'}
            plugins={[dayGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: '',
              center: '',
              right: 'prev,title,next today'
            }}
            initialView="dayGridMonth"
            dateClick={handleDateClick}
            datesSet={getEventsData}
            events={eventsData}
            eventContent={renderEventContent}
            selectable={true}
          />
        </>
        )}
      </section>
    </>
  )
}
export default UserPage
