import React, { useState, useEffect } from "react"
import "../assets/css/adminDash.css"
import axios from "axios"

const EventPopUP = (props) => {
  const [missingHour, setMissingHour] = useState("00:00")

  const [reason, setReason] = useState("")

  const [leave, setLeave] = useState(0)

  const [submitted, setSubmit] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    let con = window.confirm('You have submitted ' + reason + missingHour)
    if (con) {      
      setSubmit(true)
    }
  }

  const appliedLeave = async () => {
    let con = window.confirm("You are about to apply for a leave")
    if (con) {
      setLeave(1);
    }
  }

  const submitDayDetail = async () => {
    let params = {
      email: props.email,
      missedHour: missingHour,
      comment: reason,
      date: props.selDate,
      leave: leave,
    }
    let url = `${process.env.REACT_APP_API_URL}admin/client/add-missing-hours`
    axios.post(url, params).then((result) => {
      console.log(result.data)
    })
  }

  useEffect(() => {
    if (leave)
      submitDayDetail()
  }, [leave])

  useEffect(() => {
    if (submitted)
      submitDayDetail()
  }, [submitted])

  return (
    <>
      <div className="modal-b">
        <div className="modal-content-b">
          <button className="btn btn-sm close-btn" onClick={props.closeModal}>
            X
          </button>
          <form onSubmit={e => { handleSubmit(e) }}>
            <div className="pop-card shadow text-center">
              <div className="add-hour">
                <p className="pop-card-text mb-2 text-black">{props.selDate}</p>
                <p className="pop-card-text mb-2">Add missing hour</p>
                <div className="time-block">
                  <input className="time-text" type="time" name="time" value={missingHour}
                    onChange={e => setMissingHour(e.target.value)} />
                </div>
              </div>
              <div className="comments">
                <p className="pop-card-text mb-2">Comments</p>
                <input
                  type="text"
                  className="inp-res"
                  name="reason"
                  placeholder="Type the reason for it..."
                  id=""
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                />
              </div>
              <a onClick={appliedLeave} type="button" className="leave-btn">
                Leave
              </a>
              <button type="submit" className="sub-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default EventPopUP;