import React from 'react'
import '../Login.css'
import slackLogo from '../assets/slack-logo.png'
import mainLogo from '../assets/main-log.png'
import { Link } from 'react-router-dom'
import apple from '../assets/apple.png'
import windows from '../assets/windows.png'
import ubuntu from '../assets/ubuntu.png'

function LoginPage() {
  const handleSubmit = (e) => {
    e.preventDefault();
  }
  const slackLogin = async () => {
    window.open(`${process.env.REACT_APP_API_URL}auth/slack`, "_self");

  }
  return (
    <>
      <div className='container'>
        <div className="row justify-content-center ">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card text-white card-1 shadow border-0" style={{ height: '100vh' }}>
              <div className="card-body text-center border-none">
                <img className="logo-main" src={mainLogo} alt="logo" />
                <div>
                  <form method="post" action="/login" className="login-form" onSubmit={handleSubmit}>
                    <Link onClick={slackLogin} type="button" className="btn login-btn shadow font-a" >
                      <img src={slackLogo} alt="slack" style={{ marginRight: '20px' }} />
                      Login with your Slack Account</Link>
                  </form>
                </div>
                <p className='down-text'>Download your system tracking app from below.</p>
                <div className='d-flex download-block'>
                  <a type='button' className='btn btn-small logo-btn shadow'>
                    <img src={apple} alt='apple' height="50px" width="50px" />
                  </a>
                  {/* <a type='button' href={require("../Windows-1.0.0.zip")} className='btn btn-small logo-btn shadow' target="_blank" download>
                    <img src={windows} alt='windows' height="50px" width="50px" />
                  </a> */}
                  {/* <a type='button' href={require("../Ubuntu-1.0.0.zip")} className='btn btn-small logo-btn shadow' target="_blank" download>
                    <img src={ubuntu} alt='ubuntu' height="50px" width="50px" />
                  </a> */}
                  <a type='button' href="https://drive.google.com/file/d/1n710bwaSw5hGxM7efCHw_fVL0IzLtCJd/view?usp=sharing" 
                  className='btn btn-small logo-btn shadow' target="_blank" >
                    <img src={windows} alt='windows' height="50px" width="50px" />
                  </a>  
                  <a type='button' href="https://drive.google.com/file/d/1CSRxBlTlh9Dz98q2a926XDeTN6DXtcKT/view?usp=sharing"
                  className='btn btn-small logo-btn shadow' target="_blank">
                    <img src={ubuntu} alt='ubuntu' height="50px" width="50px" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginPage