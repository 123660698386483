import React, { useState } from 'react';

function MonthSelector(props) {
  const { currentMonth, currentYear, onPrevClick, onNextClick } = props;
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  return (
    <div className="fc-right">
      <div className="fc-container">
        <button type="button" onClick={onPrevClick} title="Previous month" aria-pressed="false" className="fc-prev-button fc-button fc-button-primary">
          <span className="fc-icon fc-icon-chevron-left"></span>
        </button>
        <h2 className="monthandyear-display" id="fc-dom-2">{`${monthNames[currentMonth]} ${currentYear}`}</h2>
        <button type="button" onClick={onNextClick} title="Next month" aria-pressed="false" className="fc-next-button fc-button fc-button-primary">
          <span className="fc-icon fc-icon-chevron-right"></span>
        </button>
      </div>
    </div>
  );
}

export default MonthSelector;


