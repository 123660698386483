import React from 'react'
import mainLogo from '../assets/main-log.png';
import avatar from '../assets/avatar.png';
import arrowDown from '../assets/arrowDown.png'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <>
     <nav className="navbar navbar-expand-lg nav-custom shadow">
    <div className="container-fluid h-60">
      <Link className="navbar-brand" to="/"><img className="logo-main-nav" src={mainLogo} alt="logo" height="120" width="120" /></Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        </ul>
          <div className="avatar-block">  
                  {/* <img src={avatar} alt='logo' />
                  <a href="/" type="button" style={{textDecoration: "none"}}><span className="user-title">User</span><img src={arrowDown} alt='logo'/></a>
                               */}
                  <a href='/'><button type="button" className="btn btn-danger">Logout</button></a>
          </div>
      </div>
    </div>
  </nav>
    </>
  )
}

export default Navbar;