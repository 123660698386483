import React from "react";
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min';

// import { Link } from 'react-router-dom'
// import UserPage from "./views/userPage"
import LoginPage from "./views/loginPage"

function App() {
  return (
    <div className="App">
      {/* <Link className="btn btn-primary" to={'/test'} ></Link> */}
    {/* <UserPage /> */}
    <LoginPage />
    </div>
  );
}

export default App;
