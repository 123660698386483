import React from 'react';

const TimeSheet = (props) => {
  const {getAllUserDetailsData,lastPage} = props;
  const data = props.data;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <table border='1px' className="full-calender-table">
        <thead>
          <tr className="full-calender-table-header">
            <th colSpan={2}>Employee</th>
            {[...Array(31)].map((_, i) => (
              <th key={i}>{i + 1}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((user) => (
            <tr key={user._id}>
              <td className="timesheet-first-td" colSpan={2}>{user.Fullname}</td>
              {user.data.map((day) => (
                <td key={day.date} className="timesheet-td">{day.total_time}</td>
              ))}
            </tr>
          ))}
          <tr>
            <td colSpan={32}>
            {lastPage ? null : (
              <button className='load-more' onClick={async () => { 
                  await getAllUserDetailsData();
                }}>
                Load More
              </button>
            )}
            </td>
          </tr>
        </tbody>

      </table>
      
    </div>
  );
};

export default TimeSheet;